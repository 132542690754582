module.exports = {
  generateFragments: [
    { fragment: 'StoryblokGrid', recursive: true },
    {
      fragment: 'StoryblokNavigationItem',
      recursive: true,
      images: [{ path: ['icon'], type: 'fluid', maxWidth: 64 }]
    },
    {
      fragment: 'StoryblokProductStory',
      replaceQuery: `
              full_slug
              id
              uuid
              lang
            `
    },
    {
      fragment: 'StoryblokMarkdown',
      addToQuery: 'childMarkdownRemark { htmlAst }'
    },
    {
      fragment: 'StoryblokTileCallToAction',
      images: [{ path: ['image'], type: 'fluid', maxWidth: 1440 }]
    },
    {
      fragment: 'StoryblokTile',
      images: [{ path: ['backgroundImage'], type: 'fluid', maxWidth: 1440 }]
    },
    {
      fragment: 'StoryblokProductHighlight',
      images: [{ path: ['image'], type: 'fluid', maxWidth: 1440 }]
    },
    {
      fragment: 'StoryblokProduct',
      images: [
        { path: ['image'], type: 'fluid', maxWidth: 1440 },
        { path: ['productSpecsImage'], type: 'fluid', maxWidth: 150 },
        { path: ['uspImage1'], type: 'fluid', maxWidth: 150 },
        { path: ['uspImage2'], type: 'fluid', maxWidth: 150 },
        { path: ['uspImage3'], type: 'fluid', maxWidth: 150 },
        { path: ['uspImage4'], type: 'fluid', maxWidth: 150 }
      ]
    },
    {
      fragment: 'StoryblokProductProperty',
      images: [{ path: ['image'], type: 'fluid', maxWidth: 600 }]
    },
    {
      fragment: 'StoryblokLocator',
      images: [
        { path: ['callIcon'], type: 'fluid', maxWidth: 64 },
        { path: ['requestIcon'], type: 'fluid', maxWidth: 64 }
      ]
    },
    {
      fragment: 'StoryblokProductPropertyGroup',
      images: [{ path: ['image'], type: 'fluid', maxWidth: 600 }]
    },
    {
      fragment: 'StoryblokProductAccessory',
      images: [{ path: ['image'], type: 'fluid', maxWidth: 600 }]
    },
    {
      fragment: 'StoryblokProductAccessoryGroup',
      images: [{ path: ['image'], type: 'fluid', maxWidth: 600 }]
    },
    {
      fragment: 'StoryblokCarouselSlide',
      images: [
        { path: ['bigImage'], type: 'fluid', maxWidth: 990 },
        { path: ['smallImage'], type: 'fluid', maxWidth: 748 }
      ]
    },
    {
      fragment: 'StoryblokGallerySlide',
      images: [{ path: ['image'], type: 'fluid', maxWidth: 1440 }]
    },
    {
      fragment: 'StoryblokImage',
      images: [{ path: ['image'], type: 'fluid', maxWidth: 1440 }]
    },
    {
      fragment: 'StoryblokFooter',
      images: [{ path: ['logo'], type: 'fluid', maxWidth: 1440 }]
    },
    {
      fragment: 'StoryblokNavigation',
      images: [{ path: ['logo'], type: 'fluid', maxWidth: 600 }]
    },
    {
      fragment: 'StoryblokDownload',
      images: [{ path: ['image'], type: 'fluid', maxWidth: 600 }],
      addToQuery: 'file { localFile { publicURL } }'
    },
    {
      fragment: 'StoryblokIconLink',
      images: [{ path: ['icon'], type: 'fluid', maxWidth: 64 }]
    },
    {
      fragment: 'StoryblokTileOverlayText',
      images: [{ path: ['image'], type: 'fluid', maxWidth: 1440 }]
    },
    {
      fragment: 'StoryblokConfigurator',
      images: [{ path: ['fallbackImage'], type: 'fluid', maxWidth: 300 }]
    },
    {
      fragment: 'StoryblokQuestionnaireAnswer',
      images: [{ path: ['image'], type: 'fluid', maxWidth: 1440 }]
    },
    {
      fragment: 'StoryblokQuestionnaireResult',
      images: [{ path: ['image'], type: 'fluid', maxWidth: 1440 }]
    },
    {
      fragment: 'StoryblokPopup',
      images: [{ path: ['icon'], type: 'fluid', maxWidth: 64 }]
    },
    {
      fragment: 'StoryblokQuickAction',
      images: [{ path: ['icon'], type: 'fixed', width: 64 }]
    }
  ],
  version: process.env.STORYBLOK_VERSION || 'draft',
  accessToken:
    process.env.STORYBLOK_TOKEN
    || (process.env.NODE_ENV === 'production'
      ? '9nCu5cnQLB9qn9wMHb0PQgtt'
      : '9nCu5cnQLB9qn9wMHb0PQgtt'),
  adminToken:
    process.env.STORYBLOK_ADMIN_TOKEN
    || '9jABIhXBa7ig45V62ilDcQtt-46763-6AW85imByboc1WTfxnEr',
  spaceId:
    process.env.STORYBLOK_SPACEID
    || (process.env.NODE_ENV === 'production' ? '58816' : '58816'),
  timeout: 30000
}
